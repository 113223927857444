import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's supply coaches for BBC Sports Personality of the Year" description="Tetley's Coaches organises, provides and manages all coach transport for the 2014 BBC Sports Personality of the Year event at Leeds Arena." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's supply coaches for BBC Sports Personality of the Year</h1>
              <p className="news-article-date">December 2013</p>
            </div>

            <div className="news-article-body">
              <p>Tetley's Coaches organises, provides and manages all coach transport for the 2014 BBC Sports Personality of the Year event at Leeds Arena. The event was organised in conjunction with the BBC and involved 14 coaches transporting over 1500 guests between hotels, Leeds Civic Hall, Leeds Town Hall and Leeds Arena.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
